<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        :variant="variant"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-row class="mt-4" v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <!-- Lisa modify for dragging sort 2022/01/14 ---- start----- -->
            <!-- <div>
              <el-button type="primary" @click="add_paysetting">{{
                $t("Add")
              }}</el-button>
            </div> -->
            <b-row>
              <b-col cols="2">
                <el-button type="primary" @click="add_paysetting" v-acl:PaymentSettingEdit.enable.show>{{
                  $t("Add")
                }}</el-button>
              </b-col>
              <b-col cols="10">
                <div v-if="!isSortableEnabled">
                  <el-button
                    type="primary"
                    @click="enableSort"
                    v-acl:PaymentSettingEdit.enable.show
                  >
                  {{ $t("Sort Order") }}
                  </el-button>
                </div>
                <div v-else>
                  <el-button type="primary" @click="disableSort" v-acl:PaymentSettingEdit.enable.show>
                    {{ $t("Save Change") }}
                  </el-button> &emsp;
                  Please drag and drop the table.
                </div>
              </b-col>
            </b-row>
            <!-- Lisa modify for dragging sort 2022/01/14 ---- end----- -->
            <p class="p-3" v-if="paysettinglist">
              <el-table
                stripe
                :class="{handshape:isSortableEnabled}"
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="paysettinglist"
                @row-click="row_clicked_event"
                ref="dragTable"
                row-key="ID"
              >
                <el-table-column :label="$t('Name') + '1'" prop="Name1">
                </el-table-column>
                <el-table-column :label="$t('Name') + '2'" prop="Name2">
                </el-table-column>
                <el-table-column :label="$t('Type')" prop="Type">
                  <template v-slot="{ row }">
                    <b-form-select
                      class="form-control"
                      v-model="row.Type"
                      @change="change_row_property(row, 'Type')"
                      size="sm"
                    >
                      <!-- <option value="0"></option> -->
                      <option
                        v-for="tt in typelist"
                        :key="tt"
                        :value="tt"
                      >
                        {{ tt }}
                      </option>
                    </b-form-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Gateway')" align="center">
                  <template v-slot="{ row }">
                    <b-form-select
                      class="form-control"
                      v-model="row.GlobalPayment"
                      @change="change_row_property(row, 'GlobalPayment')"
                      size="sm"
                    >
                      <option :value="0">-</option>
                      <option :value="1">GlobalPayment</option>
                      <option :value="3">Snappay</option>
                    </b-form-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Surcharge Rate')" prop="SurchargeRate">
                  <template v-slot="{ row }">
                    {{ parseFloat(row.SurchargeRate).toFixed(2) + '%' }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Status')" align="center">
                  <template v-slot="{ row }">
                    <b-form-checkbox
                      class="form-checkbox"
                      v-model="row.Enabled"
                      value="1"
                      unchecked-value="0"
                      switch
                      style="margin-left: 1.6rem;"
                      @change="change_row_property(row, 'Enabled')"
                    />
                  </template>
                </el-table-column>
                <el-table-column label="Kiosk" align="center">
                  <template v-slot="{ row }">
                    <b-form-checkbox
                      class="form-checkbox"
                      v-model="row.KiosKEnabled"
                      value="1"
                      unchecked-value="0"
                      switch
                      style="margin-left: 1.6rem;"
                      @change="change_row_property(row, 'KiosKEnabled')"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('No Change')"
                  prop="NoChange"
                  align="center"
                >
                  <template v-slot="{ row }">
                    <b-form-checkbox
                      class="form-checkbox"
                      v-model="row.NoChange"
                      value="1"
                      unchecked-value="0"
                      switch
                      style="margin-left: 1.6rem;"
                      @change="change_row_property(row, 'NoChange')"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('None Revenue')"
                  prop="NoneRevenue"
                  align="center"
                >
                  <template v-slot="{ row }">
                    <b-form-checkbox
                      class="form-checkbox"
                      v-model="row.NoneRevenue"
                      value="1"
                      unchecked-value="0"
                      switch
                      style="margin-left: 1.6rem;"
                      @change="change_row_property(row, 'NoneRevenue')"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4" v-if="currpage == 'edit'">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name') + '1'">
                      <b-input
                        placeholder="Name1"
                        class="form-control"
                        v-model="cur_paysetting.Name1"
                        :disabled="cur_opt != 'add'"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Name') + '2'">
                      <b-input
                        placeholder="Name2"
                        class="form-control"
                        v-model="cur_paysetting.Name2"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Type')">
                      <select
                        class="form-control"
                        v-model="cur_paysetting.Type"
                      >
                        <option v-for="tt in typelist" :key="tt">
                          {{ tt }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Exchange Rate')">
                      <b-input
                        placeholder="Exchange Rate"
                        class="form-control"
                        v-model="cur_paysetting.ExchangeRate"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Gateway')">
                      <select
                        class="form-control"
                        v-model="cur_paysetting.GlobalPayment"
                      >
                        <option value="0">-</option>
                        <option value="1">Global Payment Machine</option>
                        <option value="3">Snappay</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('Surcharge Rate')">
                      <b-input
                        placeholder="SurchargeRate"
                        class="form-control"
                        v-model="cur_paysetting.SurchargeRate"
                      />
                    </base-input>
                  </b-col>
                </b-row>
                <!-- Lisa modify for dragging sort 2022/01/14 ---- start----- -->
                <!-- <b-row>
                  <b-col>
                    <base-input :label="$t('Sort Order')">
                      <b-input
                        placeholder="Sort Order"
                        class="form-control"
                        v-model="cur_paysetting.Seq"
                      />
                    </base-input>
                  </b-col>
                </b-row> -->
                 <!-- Lisa modify for dragging sort 2022/01/14 ---- end----- -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('Enabled')">
                      <select
                        class="form-control"
                        v-model="cur_paysetting.Enabled"
                      >
                        <option value="0">{{ $t("No") }}</option>
                        <option value="1">{{ $t("Yes") }}</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input label="Kiosk">
                      <select
                        class="form-control"
                        v-model="cur_paysetting.KiosKEnabled"
                      >
                        <option value="0">{{ $t("No") }}</option>
                        <option value="1">{{ $t("Yes") }}</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('No Change')">
                      <select
                        class="form-control"
                        v-model="cur_paysetting.NoChange"
                      >
                        <option value="0">{{ $t("No") }}</option>
                        <option value="1">{{ $t("Yes") }}</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <base-input :label="$t('None Revenue')">
                      <select
                        class="form-control"
                        v-model="cur_paysetting.NoneRevenue"
                      >
                        <option value="0">{{ $t("No") }}</option>
                        <option value="1">{{ $t("Yes") }}</option>
                      </select>
                    </base-input>
                  </b-col>
                </b-row>

                <b-row class="justify-content-lg-center">
                  <b-col class="text-center" lg="4">
                    <el-button type="primary" @click="form_cancel">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center" lg="4">
                    <el-button type="primary" @click="form_submit">{{
                      $t("Submit")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [flatPicker.name]: flatPicker,
  },
  data() {
    return {
      currpage: "list",
      cur_paysetting: null,
      cur_opt: null,
      new_paysetting: {
        ID: "",
        Name1: "",
        Name2: "",
        Type: "", // Cash, Check, Debit, CreditCard, GiftCard, VIP, Point, Online, ForeignCash
        ExchangeRate: 1,
        FontName1: "Calibri",
        FontName2: "Microsoft YaHei",
        FontSize1: "15",
        FontSize2: "12.5",
        Bold1: 1,
        Bold2: 1,
        GlobalPayment: 0,
        Seq: 0,
        Enabled: 1,
        KiosKEnabled: 0,
        NoChange: 1,
        NoneRevenue: 0,
        IcoFileData: "",
        PaymentButton: 0,
        SurchargeRate: 0.0,
      },
      paysettinglist: null,
      typelist: [
        "Cash",
        "Debit",
        "CreditCard",
        "Check",
        "Point",
        "VIPCredit",
        "GiftCard",
        "Voucher",
        "WeChatPay",
        "AliPay",
        "Others",
      ],

      token: "",
      user_id: 0,

      dismessage: "",
      countdown: 0,
      variant: "success",

      sortable: null,
      isSortableEnabled: false,

    };
  },
  methods: {
    hasUtilityAuth,
    disableSort() {
      this.sortable.option('disabled', true);
      this.isSortableEnabled = false;
      this.order_submit();
    },
    enableSort() {
      if (this.paysettinglist.length <= 0) {
        return;
      }
      this.setSort();
      // this.sortable.option('disabled', false);
      this.isSortableEnabled = true;
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      )[0];
      this.sortable = Sortable.create(el, {
        disabled: false,
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          // console.log("onEnd targetRow A:", evt.newIndex, evt.oldIndex, targetRow, this.paysettinglist);
          const targetRow = this.paysettinglist.splice(evt.oldIndex, 1)[0];
          this.paysettinglist.splice(evt.newIndex, 0, targetRow);
          // console.log("onEnd targetRow B:", this.paysettinglist);
        },
      });
    },
    async order_submit() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var orderlist = [];
      for (var i = 0; i < this.paysettinglist.length; i++){
        orderlist.push({ID:this.paysettinglist[i].ID, Seq:i+1});
      }
      bodyFormData.append("SortOrderList", JSON.stringify(orderlist));
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Paysetting/sort_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.paysettinglist = rt.data.paysettings;
              // that.$router.go();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    add_paysetting() {
      this.currpage = "edit";
      this.cur_paysetting = this.new_paysetting;
      this.cur_opt = "add";
    },
    row_clicked_event(row, column, event) {
      // console.log("row_clicked_event", row, column, event);
      if (column && 
        (
          column.label == this.$t("Type") ||
          column.label == this.$t("Payment Method") ||
          column.label == this.$t("Status") ||
          column.label == "Kiosk" ||
          column.label == this.$t("No Change") ||
          column.label == this.$t('Gateway')
        )
      ) {
        return;
      }
      if (!hasUtilityAuth('PaymentSettingEdit')) return;
      this.currpage = "edit";
      this.cur_paysetting = JSON.parse(JSON.stringify(row));
      this.cur_opt = "update";
    },
    form_cancel() {
      this.currpage = "list";

      // Lisa modify for dragging sort 2022/01/14 ---- start-----
      // clear sort status
      if(this.isSortableEnabled){
        this.sortable.option('disabled', true);
        this.isSortableEnabled = false;
        // refresh list data
        this.get_paysetting_list();
      }
      // Lisa modify for dragging sort 2022/01/14 ---- end-----
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }

      if (this.cur_opt == 'add') {
        this.cur_paysetting.ID = this.cur_paysetting.Name1.replace(/[^A-Za-z]/g, '');
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_paysetting) {
        if (this.cur_paysetting[key])
          bodyFormData.append(key, this.cur_paysetting[key]);
      }

      axios({
        method: "post",
        url: "/Api/Web/Paysetting/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              that.$router.go();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async change_row_property(row, attr) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      var that = this;
      bodyFormData.append("ID", row["ID"]);
      bodyFormData.append(attr, row[attr]);

      axios({
        method: "post",
        url: "/Api/Web/Paysetting/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 2;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_paysetting_list() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Paysetting",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.paysettinglist = rt.data.paysettings;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
        this.variant = "success";
      }
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_paysetting_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
/* Lisa modify for dragging sort 2022/01/14 ---- start----- */
.handshape{
  cursor: pointer;
}
/* Lisa modify for dragging sort 2022/01/14 ---- end----- */
</style>
